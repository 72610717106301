<template>
  <div class="flex justify-center">
    <div class="fmu-container px-10 py-10 font-thin">
      <strong class="text-2xl font-bold">Datenschutzhinweise gemäß Art. 13 DSGVO</strong>
      <br> <br>
      <h2 class="text-md font-bold">Name und Anschrift des Verantwortlichen</h2> <br>
      <p>Verantwortliche Stelle im Sinne der Datenschutz-Grundverordnung (DSGVO) und anderer datenschutzrechtlicher
        Bestimmungen ist:</p>
      <br>
      <p>
        Christoph Hutter und Andreas Unger <br>
        Hutter & Unger GmbH<br>
        Gewerbestraße 2c<br>
        86637 Wertingen<br>
        Deutschland
      </p>
      <br>
      <p>Telefon: +49 (0)8272 9940-0 <br>
        E-Mail: info@hutter-unger.de</p>
      <br>
      <h2 class="text-md font-bold">Name und Anschrift des Datenschutzbeauftragten</h2> <br>
      <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
      <br>
      <p>
        3 P Datenschutz GmbH <br>
        Stefan Leißl<br>
        Sanderstraße 47<br>
        86161 Augsburg<br>
        Telefon: 0821 6508 8582<br>
        E-Mail: leissl@3-p-datenschutz.de
      </p>
      <br>
      <h2 class="text-md font-bold">Allgemeines zur Datenverarbeitung</h2> <br>
      <h2 class="text-md font-bold">Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h2> <br>
      <p>
        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die
        Rechtsgrundlage im Datenschutzhinweis nicht genau genannt wird, gilt Folgendes:
        Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a i.V.m. Art. 7 DSGVO. Die
        Rechtsgrundlage für die
        Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie zur Beantwortung
        von Anfragen ist
        Art. 6 Abs. 1 lit. b DSGVO. Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
        Verpflichtungen ist Art. 6 Abs. 1
        lit. c DSGVO. Ist die Verarbeitung Ihrer Daten zur Wahrung eines berechtigten Interesses unseres Unternehmens
        oder eines Dritten
        erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte
        Interesse nicht, so
        dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung. Für den Fall, dass lebenswichtige
        Interessen der betroffenen
        Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen,
        dient Art. 6 Abs. 1
        lit. d DSGVO als Rechtsgrundlage
      </p>
      <br>
      <h2 class="text-md font-bold">Datenlöschung und Speicherdauer</h2> <br>
      <p>
        Wir halten uns an die Grundsätze der Datenminimierung gem. Art. 5 Abs. 1 lit. c DSGVO und Speicherbegrenzung
        gem. Art. 5 Abs. 1
        lit. e DSGVO. Wir speichern Ihre personenbezogenen Daten nur so lange, wie dies zur Erreichung der hier
        genannten Zwecke
        erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen Aufbewahrungsfristen vorsehen. Nach Wegfall des
        jeweiligen Zwecks
        bzw. nach Ablauf dieser Aufbewahrungsfristen, werden die entsprechenden Daten schnellstmöglich gelöscht.
      </p>
      <br>
      <h2 class="text-md font-bold">Hinweis zur Datenweitergabe an Drittstaaten</h2> <br>

      <p>Auf unserer Website sind auch Tools von Unternehmen mit Sitz in Drittstaaten (dazu zählen namentlich die USA)
        eingebunden. Sind
        diese Tools aktiv, können Ihre personenbezogenen Daten an die Server der jeweiligen Unternehmen übermittelt
        werden. Das
        Datenschutzniveau in Drittstaaten entspricht in der Regel nicht dem EU-Datenschutzrecht. Damit besteht das
        Risiko, dass Ihre Daten
        an Behörden dieser Staaten weitergegeben werden. Einfluss auf diese Verarbeitungstätigkeiten haben wir
        nicht.</p>
      <br>
      <h2 class="text-md font-bold">Externe Links</h2> <br>
      <p>
        Diese Webseite kann Links zu Webseiten Dritter bzw. zu anderen Webseiten in unserer Verantwortung enthalten.
        Folgen Sie einem
        Link zu einer der Webseiten außerhalb unserer Verantwortung, beachten Sie bitte, dass diese Webseiten über ihre
        eigenen
        Datenschutzinformationen verfügen. Für diese fremden Webseiten und deren Datenschutzhinweise übernehmen wir
        keine
        Verantwortung oder Haftung. Überprüfen Sie daher vor Nutzung dieser Webseiten, ob Sie mit den dortigen
        Datenschutzerklärungen
        einverstanden sind.
      </p>
      <br>
      <p>
        Externe Links erkennen Sie entweder daran, dass sie farblich etwas abgesetzt vom restlichen Text oder
        unterstrichen dargestellt
        werden. Ihr Cursor zeigt Ihnen externe Links an, wenn Sie diesen über einen solchen Link bewegen. Erst wenn Sie
        auf einen externen
        Link klicken, werden Ihre personenbezogenen Daten zum Ziel des Links übertragen. Dabei erhält der Betreiber der
        anderen Webseite
        insbesondere Ihre IP-Adresse, den Zeitpunkt, zu dem Sie den Link angeklickt haben, die Seite, auf der Sie den
        Link angeklickt haben,
        sowie weitere Informationen, die Sie in den Datenschutzhinweisen des jeweiligen Anbieters finden.
      </p>
      <br>
      <p>
        Bitte beachten Sie auch, dass einzelne Links zu einer Datenübermittlung außerhalb des europäischen
        Wirtschaftsraums führen
        können. Hierdurch könnten ausländische Behörden Zugriff auf Ihre Daten erhalten. Möglicherweise stehen Ihnen
        keine Rechtsbehelfe
        gegen diese Datenzugriffe zu. Wenn Sie nicht möchten, dass Ihre personenbezogenen Daten zum Linkziel übertragen
        werden oder
        gar unerwünscht dem Zugriff ausländischer Behörden ausgesetzt werden, klicken Sie bitte keine Links an.
      </p>
      <br>
      <h2 class="text-md font-bold">Rechte der betroffenen Person</h2> <br>
      <p>
        Als Betroffener im Sinne der DSGVO haben Sie die Möglichkeit verschiedene Rechte geltend zu machen. Die sich aus
        der DSGVO
        ergebenden Betroffenenrechte sind das Auskunftsrecht (Artikel 15), das Recht auf Berichtigung (Artikel 16), das
        Recht auf Löschung
        (Artikel 17), das Recht auf Einschränkung der Verarbeitung (Artikel 18), das Widerspruchsrecht (Artikel 21), das
        Recht auf
        Beschwerde bei einer Aufsichtsbehörde und das Recht auf Datenübertragbarkeit (Artikel 20).
      </p>
      <br>
      <strong class="underline">Widerrufsrecht:</strong>
      <p>
        Einige Datenverarbeitungen können nur mit Ihrer ausdrücklichen Einwilligung erfolgen. Sie haben jederzeit die
        Möglichkeit Ihre erteilte
        Einwilligung zu widerrufen. Die Rechtmäßigkeit der Datenverarbeitung bis zum Widerruf wird durch diesen
        allerdings nicht berührt.
      </p>
      <br>
      <p class="underline">Widerspruchsrecht:</p>
      <p>Beruht die Verarbeitung auf Art. 6 Absatz 1 lit. e oder f DSGVO, können Sie als Betroffener, aus Gründen, die
        sich aus Ihrer
        besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten
        widersprechen.
        Dieses Recht steht Ihnen auch bei auf diese Bestimmungen gestütztem Profiling iSd Art. 4 Z 4 DSGVO zu. Sofern
        wir kein
        Berechtigtes Interesse für die Verarbeitung nachweisen können, welche Ihre Interessen, Rechte und Freiheiten
        überwiegen oder eine
        Verarbeitung, der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient, werden wir die
        Verarbeitung ihrer
        Daten nach erfolgten Widerspruch unterlassen.</p> <br>
      <p>
        Dient die Verarbeitung von personenbezogenen Daten der Betreibung von Direktwerbung, so haben Sie auch das
        Recht, jederzeit
        Widerspruch zu erheben. Dasselbe gilt für Profiling, welches mit Direktwerbung in Verbindung steht. Auch hier
        werden wir
        personenbezogene Daten nicht mehr verarbeiten, sobald Sie Widerspruch erheben.
      </p> <br>
      <p class="underline">Recht auf Beschwerde bei einer Aufsichtsbehörde:</p>
      <p>Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO
        verstößt, steht
        Ihnen unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf
        Beschwerde bei einer
        Aufsichtsbehörde, insbesondere in dem Mitgliedsstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts
        des mutmaßlichen
        Verstoßes, zu.</p> <br>
      <p class="underline">Recht auf Datenübertragbarkeit:</p>
      <p>Werden Ihre Daten aufgrund einer Einwilligung oder Erfüllung eines Vertrages automatisiert verarbeitet, haben
        Sie das Recht, diese
        Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht,
        die
        Übertragung und Bereitstellung der Daten an einen anderen Verantwortlichen zu fordern, soweit dies technisch
        umsetzbar ist.</p>
      <br>
      <p class="underline">Recht auf Auskunft, Berichtigung und Löschung:</p>
      <p>Sie haben das Recht, Auskunft über Ihre verarbeiteten personenbezogenen Daten bezüglich des Zwecks der
        Datenverarbeitung, der
        Kategorien, der Empfänger sowie der Dauer der Speicherung zu erhalten. Bei Fragen zu diesem Thema oder auch zu
        anderen
        Themen bezüglich personenbezogener Daten können Sie sich selbstverständlich über die im Impressum angegebenen
        Kontaktmöglichkeiten an uns wenden.</p> <br>
      <p class="underline">Recht auf Einschränkung der Verarbeitung:</p>
      <p>Sie können jederzeit die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten geltend machen. Dazu
        müssen Sie eine
        der folgenden Voraussetzungen erfüllen:</p>
      <br>
      <ul class="list-disc pl-6">
        <li>Sie bestreiten die Richtigkeit der personenbezogenen Daten. Für die Dauer der Überprüfung der Richtigkeit
          haben Sie das
          Recht, eine Einschränkung der Verarbeitung zu verlangen.
        </li>
        <li>Erfolgt eine Verarbeitung unrechtmäßig, so können Sie alternativ zur Löschung die Einschränkung der Nutzung
          der Daten
          beantragen.
        </li>
        <li>Sollten wir Ihre personenbezogenen Daten für die Zwecke der Verarbeitung nicht mehr benötigen, Sie aber die
          Daten für die
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, können Sie alternativ zur Löschung,
          die
          Einschränkung der Verarbeitung beantragen.
        </li>
        <li>Legen Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO ein, wird eine Abwägung zwischen
          Ihren und
          unseren Interessen durchgeführt. Bis diese Abwägung erfolgt ist, haben Sie das Recht, die Einschränkung der
          Verarbeitung zu
          beantragen.
        </li>
      </ul>
      <br>
      <p>Eine Einschränkung der Verarbeitung hat zur Folge, dass die personenbezogenen Daten, abgesehen von der
        Speicherung, nur mit
        Ihrer Einwilligung oder zur Geltendmachung, zur Ausübung bzw. Verteidigung von Rechtsansprüchen oder zum Schutz
        der Rechte
        einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der
        Union oder eines
        Mitgliedstaates verarbeitet werden dürfen.</p> <br>
      <h2 class="text-md font-bold">Bereitstellung der Webseite (Webhoster)</h2> <br>
      <p>Unsere Webseite wird gehostet bei:</p> <br>
      <p>
        Microsoft Ireland Operations Ltd. <br>
        One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521
        Irland
      </p> <br>
      <p>Der Serverstandort ist Niederlande.</p> <br>
      <p>
        Wenn Sie unsere Webseite aufrufen, erheben und speichern wir automatisch Informationen in sogenannten
        Server-Logfiles. Diese
        Informationen übermittelt Ihr Browser automatisch an unseren Server bzw. an den Server unseres
        Hostingunternehmens.
      </p> <br>
      <p>Dies sind:</p> <br>
      <ul class="list-disc pl-6">
        <li>IP-Adresse des Endgerätes des Webseitenbesuchers</li>
        <li>Verwendetes Gerät</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Betriebssystem des Besuchers</li>
        <li>Browsertyp und Version</li>
        <li>Name der abgerufenen Datei</li>
        <li>Zeitpunkt der Serveranfrage</li>
        <li>Menge der Daten</li>
        <li>Information, ob der Abruf der Daten erfolgreich war</li>
      </ul>
      <br>
      <p>Diese Daten werden nicht mit anderen Datenquellen zusammengeführt.</p>
      <br>
      <p>
        Statt diese Webseite auf einem eigenen Server zu betreiben, können wir sie auch auf dem Server eines externen
        Dienstleisters
        (Hostingunternehmen) betreiben lassen, den wir in diesem Fall weiter oben genannt haben. Die von dieser Webseite
        erfassten
        personenbezogenen Daten werden dann auf den Servern des Hostingunternehmens gespeichert. Ergänzend zu den oben
        genannten
        Daten speichert der Webhoster für uns z.B. auch Kontaktanfragen, Kontaktdaten, Namen, Webseitezugriffsdaten,
        Meta- und
        Kommunikationsdaten, Vertragsdaten und sonstige Daten, die über eine Website generiert werden.
      </p> <br>
      <p>
        Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse
        ist die technisch
        fehlerfreie Darstellung und Optimierung dieser Webseite. Sofern die Webseite aufgerufen wird, um mit uns in
        Vertragsverhandlungen
        zu gehen oder einen Vertrag abzuschließen, dient als weitere Rechtsgrundlage (Art. 6 Abs. 1 lit. b DSGVO). Für
        den Fall, dass wir ein
        Hostingunternehmen beauftragt haben, besteht ein Vertrag über Auftragsverarbeitung mit diesem Dienstleister.
      </p>
      <br>
      <h2 class="text-md font-bold">Einsatz von Local Storage Items, Session Storage Items und Cookies</h2> <br>
      <p>Unsere Webseite verwendet Local Storage Items, Session Storage Items und/oder Cookies. Beim Local Storage
        handelt es sich um
        einen Mechanismus, welcher die Speicherung von Daten innerhalb des Browsers auf Ihrem Endgerät ermöglicht. Diese
        Daten
        beinhalten meist Benutzerpräferenzen, wie beispielsweise den „Tag-“ oder „Nachtmodus“ einer Webseite, und
        bleiben so lange
        erhalten, bis Sie die Daten manuell löschen. Session Storage ist dem Local Storage sehr ähnlich, wohingegen die
        Speicherdauer nur
        während der aktuellen Sitzung, also bis zum Schließen des aktuellen Tabs andauert. Danach werden die Session
        Storage Items von
        Ihrem Endgerät gelöscht. Bei Cookies handelt es sich um Informationen, welche ein Webserver (Server, der
        Webinhalte bereitstellt)
        auf Ihrem Endgerät hinterlegt, um dieses Endgerät identifizieren zu können. Sie werden entweder vorübergehend
        für die Dauer einer
        Sitzung (Session-Cookies) und nach Ende Ihres Besuchs einer Webseite gelöscht oder dauerhaft (permanente
        Cookies) auf Ihrem
        Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser
        erfolgt.</p>
      <br>
      <p>Diese Objekte können auch von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite
        betreten (ThirdParty-Requests). Dadurch wird es uns als Betreiber und Ihnen als Besucher dieser Webseite
        ermöglicht, bestimmte Dienstleistungen
        von Dritten, die auf dieser Webseite installiert sind, in Anspruch zu nehmen. Beispiele dafür sind etwa die
        Abwicklung von
        Zahlungsdienstleistungen oder die Anzeige von Videos.</p>
      <br>
      <p>Diese Mechanismen haben vielfältige Einsatzmöglichkeiten. Sie können die Funktionsfähigkeit einer Webseite
        verbessern,
        Warenkorbfunktionen steuern, die Sicherheit und den Komfort der Webseitennutzung erhöhen sowie Analysen
        bezüglich
        Besucherströmen und -verhalten durchführen. In Abhängigkeit von den einzelnen Funktionen sind diese
        datenschutzrechtlich
        einzuordnen. Sind sie notwendig für den Betrieb der Webseite und zur Bereitstellung bestimmter Funktionen
        gedacht
        (Warenkorbfunktion) oder dienen zur Optimierung der Webseite (z.B. Cookies zur Messung des Besucherverhaltens),
        dann erfolgt ihr
        Einsatz auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Webseitenbetreiber haben wir ein berechtigtes
        Interesse an der
        Speicherung von Local Storage Items, Session Storage Items und Cookies zur technisch fehlerfreien und
        optimierten Bereitstellung
        unserer Dienste. In allen anderen Fällen erfolgt die Speicherung von Local Storage Items, Session Storage Items
        und Cookies nur
        nach Ihrer ausdrücklichen Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p> <br>
      <p>Soweit Local Storage Item, Session Storage oder Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
        werden,
        informieren wir Sie hierüber im Rahmen dieses Datenschutzhinweises gesondert. Ihre erforderliche Einwilligung
        wird abgefragt und
        kann jederzeit widerrufen werden.</p> <br>
      <h2 class="text-md font-bold">Einsatz von externen Diensten</h2> <br>
      <p>
        Auf unserer Webseite sind externe Dienste im Einsatz. Externe Dienste sind Dienste von Drittanbietern, die auf
        unserer Webseite
        eingesetzt werden. Dies kann aus unterschiedlichen Gründen erfolgen, beispielsweise für das Einbetten von Videos
        oder zur
        Sicherheit der Webseite. Bei der Benutzung dieser Dienste werden personenbezogene Daten auch an die jeweiligen
        Anbieter dieser
        externen Dienste weitergegeben. Wenn wir kein berechtigtes Interesse am Einsatz dieser Dienste haben, holen wir
        vor der
        Verwendung Ihre jederzeit widerrufbare Einwilligung als Besucher unserer Webseite ein (Art. 6 Abs. 1 lit. a
        DSGVO).
      </p> <br>
      <h2 class="text-md font-bold">Analytics</h2> <br>
      <p>
        Zur Analyse des Nutzerverhaltens verarbeiten wir personenbezogene Daten der Webseitenbesucher. Wir sind durch
        die Auswertung
        der gewonnenen Daten in der Lage, Informationen über die Nutzung der einzelnen Komponenten unserer Webseite
        zusammenzustellen. Hierdurch können wir die Nutzerfreundlichkeit unserer Webseite erhöhen. Mittels der
        eingesetzten Analyse-Tools könnten z.B. Nutzerprofile für die Ausspielung zielgerichteter oder
        interessenbezogener Werbebotschaften erstellt, unsere
        Webseitenbesucher beim nächsten Besuch unserer Webseite erkannt, ihr Klick-/Scrollverhalten, ihre Downloads
        gemessen,
        Heatmaps erstellt, Seitenaufrufe erkannt, die Besuchsdauer oder die Absprungraten gemessen, sowie die Herkunft
        der
        Webseitenbesucher (Stadt, Land, von welcher Seite kommt der Besucher) zurückverfolgt werden. Mit Hilfe der
        Analyse-Tools lassen
        sich unsere Marktforschungs- und Marketingaktivitäten verbessern.
      </p>
      <br>
      <p>
        Zu einer Verarbeitung kommt es nur, wenn Sie in diese Datenverarbeitung (über unser Einwilligungsbanner auf der
        Webseite)
        einwilligen. Die Rechtsgrundlage für diese Verarbeitung ist die Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ohne
        Ihre Einwilligung erfolgt
        die Datenverarbeitung in der oben beschriebenen Weise nicht. Wenn Sie Ihre Einwilligung (z.B. über das
        Einwilligungsbanner oder
        andere auf dieser Website vorgesehene Möglichkeiten) widerrufen, beenden wir diese Datenverarbeitung. Die
        Rechtmäßigkeit der bis
        zum Widerruf erfolgten Verarbeitung bleibt davon unberührt.
      </p> <br>
      <h2 class="text-md font-bold">Google Analytics</h2> <br>
      <p>Wir nutzen auf unserer Webseite den Dienst Google Analytics. Anbieter des Dienstes ist die Google Ireland Ltd.,
        Gordon House,
        Barrow Street Dublin 4, Irland.</p> <br>
      <p>Durch die Nutzung des Dienstes kann es zu einer Datenübertragung in ein Drittland kommen (USA).</p> <br>
      <p>Weitere Informationen sind in den Datenschutzinformationen des Anbieters unter folgender URL zu finden:
        https://policies.google.com/privacy.</p> <br>
      <p>Der Dienst setzt auf unserer Webseite die folgenden Cookies ein:</p>
      <br>
      <table class="min-w-full bg-white">
        <thead>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th class="py-2 px-4 border-b border-gray-200">Name</th>
          <th class="py-2 px-4 border-b border-gray-200">Speicherdauer</th>
          <th class="py-2 px-4 border-b border-gray-200">Typ</th>
          <th class="py-2 px-4 border-b border-gray-200">Zweck</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="py-2 px-4 border-b border-gray-200">_ga</td>
          <td class="py-2 px-4 border-b border-gray-200">400 Tage</td>
          <td class="py-2 px-4 border-b border-gray-200">1st-Party Cookie</td>
          <td class="py-2 px-4 border-b border-gray-200">Enthält eine zufallsgenerierte User-ID. Anhand dieser ID kann
            Google Analytics wiederkehrende User auf dieser Webseite
            wiedererkennen und die Daten von früheren Besuchen
            zusammenführen.
          </td>
        </tr>
        <tr>
          <td class="py-2 px-4 border-b border-gray-200">_ga_R38LFZR8JP</td>
          <td class="py-2 px-4 border-b border-gray-200">400 Tage</td>
          <td class="py-2 px-4 border-b border-gray-200">1st-Party Cookie</td>
          <td class="py-2 px-4 border-b border-gray-200">Sammelt Daten dazu, wie oft ein Benutzer eine Webseite besucht
            hat, sowie Daten für den ersten und letzten Besuch.
          </td>
        </tr>
        <tr>
          <td class="py-2 px-4 border-b border-gray-200">_gat_gtag_UA_19511266_13</td>
          <td class="py-2 px-4 border-b border-gray-200">1 Minute</td>
          <td class="py-2 px-4 border-b border-gray-200">1st-Party Cookie</td>
          <td class="py-2 px-4 border-b border-gray-200">Stellt sicher das Daten an Google Analytics nur einmal pro
            Minute
            übermittelt werden. Solange er gesetzt ist, werden bestimmte
            Datenübertragungen verhindert.
          </td>
        </tr>
        <tr>
          <td class="py-2 px-4 border-b border-gray-200">_gid</td>
          <td class="py-2 px-4 border-b border-gray-200">24 Stunden</td>
          <td class="py-2 px-4 border-b border-gray-200">1st-Party Cookie</td>
          <td class="py-2 px-4 border-b border-gray-200">Dieser Cookie ordnet einem User eine ID zu, damit der
            Webtracker die Aktionen des Nutzers unter diese ID
            zusammenfassen kann.
          </td>
        </tr>
        </tbody>
      </table>
      <br>
      <h2 class="text-md font-bold">Hosting</h2> <br>
      <p>Unter Hosting versteht man ist das Bereitstellen von Webspace sowie das Unterbringen von Webseiten. Damit
        unsere Webseite
        dargestellt werden kann, muss sie von einem Webhoster gehostet werden. Dabei kommt es zur Übertragung und
        Speicherung
        personenbezogener Daten auf den Servern des Hosts. Insbesondere werden die IP-Adressen, Meta- und
        Kommunikationsdaten der
        Nutzer sowie Daten über die Webseitenzugriffe verarbeitet. Wenn ein Webseitenbesucher die Seite aufruft, wird
        eine Verbindung zu
        den Servern des Webhosts hergestellt. Hierbei kommt es zu einer Verarbeitung personenbezogener Daten des
        Webseitenbesuchers.</p> <br>
      <p>Wir stützen diese Verarbeitung auf ein berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO).</p> <br>
      <p>Unser berechtigtes Interesse besteht darin, unsere Webseite darstellen und im Internet zur Verfügung stellen zu
        können.</p>
      <br>
      <h2 class="text-md font-bold">Microsoft-Azure</h2> <br>
      <p>Wir nutzen auf unserer Webseite den Dienst Microsoft-Azure. Anbieter des Dienstes ist die Microsoft Ireland
        Operations Ltd., One
        Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521, Irland.</p> <br>
      <p>Da dieser Dienst lokal am Webserver gehostet wird, findet keine Datenübertragung an Dritte statt.</p> <br>
      <p>Der Dienst setzt auf unserer Webseite die folgenden Cookies ein:</p>
      <br>
      <table class="min-w-full bg-white">
        <thead>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th class="py-2 px-4 border-b border-gray-200">Name</th>
          <th class="py-2 px-4 border-b border-gray-200">Speicherdauer</th>
          <th class="py-2 px-4 border-b border-gray-200">Typ</th>
          <th class="py-2 px-4 border-b border-gray-200">Zweck</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="py-2 px-4 border-b border-gray-200">ApplicationGatewayAffinity</td>
          <td class="py-2 px-4 border-b border-gray-200">Session</td>
          <td class="py-2 px-4 border-b border-gray-200">1st-Party Cookie</td>
          <td class="py-2 px-4 border-b border-gray-200">Dieses Cookie wird von Azure Apps verwendet, um eine
            Benutzersitzung auf demselben Server zu halten.
          </td>
        </tr>
        <tr>
          <td class="py-2 px-4 border-b border-gray-200">ApplicationGatewayAffinityCORS</td>
          <td class="py-2 px-4 border-b border-gray-200">Session</td>
          <td class="py-2 px-4 border-b border-gray-200">1st-Party Cookie</td>
          <td class="py-2 px-4 border-b border-gray-200">Dieses Cookie wird von Azure Apps verwendet, um eine
            Benutzersitzung auf demselben Server zu halten.
          </td>
        </tr>
        </tbody>
      </table>
      <br>
      <h2 class="text-md font-bold">Softwareframework</h2> <br>
      <p>
        Softwareframeworks erleichtern die Interaktion mit einer Plattform, indem sie eine standardisierte Schnittstelle
        zu dieser herstellen.
        Frameworks werden verwendet, um den Entwicklungsaufwand bei wiederkehrenden Anforderungen an Software zu
        reduzieren.
      </p> <br>
      <p>Zu einer Verarbeitung kommt es nur, wenn Sie in diese Datenverarbeitung (über unser Einwilligungsbanner auf der
        Webseite)
        einwilligen. Die Rechtsgrundlage für diese Verarbeitung ist die Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ohne
        Ihre Einwilligung erfolgt
        die Datenverarbeitung in der oben beschriebenen Weise nicht. Wenn Sie Ihre Einwilligung (z.B. über das
        Einwilligungsbanner oder
        andere auf dieser Website vorgesehene Möglichkeiten) widerrufen, beenden wir diese Datenverarbeitung. Die
        Rechtmäßigkeit der bis
        zum Widerruf erfolgten Verarbeitung bleibt davon unberührt.</p> <br>
      <h2 class="text-md font-bold">.NET Framework</h2> <br>
      <p>Wir nutzen auf unserer Webseite den Dienst .NET Framework. Anbieter des Dienstes ist die Microsoft Ireland
        Operations Ltd., One
        Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521, Irland.</p> <br>
      <p>Durch die Nutzung des Dienstes kann es zu einer Datenübertragung in ein Drittland kommen (USA).</p> <br>
      <p>Weitere Informationen sind in den Datenschutzinformationen des Anbieters unter folgender URL zu finden:
        https://privacy.microsoft.com/de-de/privacystatement.</p> <br>
      <p>Wir stützen diese Verarbeitung auf ein berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO).</p> <br>
      <p>Diese Anwendung wird benötigt um die uneingeschränkte Funktionalität der Webseite zu gewährleisten.</p> <br>

      <h2 class="text-md font-bold">Kontaktformular</h2> <br>
      <p>Auf unserer Webseite besteht die Möglichkeit, uns über ein Kontaktformular zu benachrichtigen. Für die
        Kontaktaufnahme über dieses
        Formular werden insbesondere Ihre Kontaktdaten benötigt.</p> <br>
      <p>Als Rechtsgrundlage wird hierbei auf die Verarbeitung zum Zwecke der Vertragserfüllung bzw. vorvertraglicher
        Maßnahmen gem. Art.
        6 Abs. 1 lit. b DSGVO verwiesen. Außerdem kann ein berechtigtes Interesse vorliegen, um Geschäftsbeziehungen zu
        pflegen oder
        Ihre Anfrage aus sonstigen Gründen zu beantworten.</p> <br>
      <p>Die Rechtsgrundlage für die Verarbeitung Ihrer Daten wäre diesfalls Art. 6 Abs. 1 lit. f DSGVO.</p> <br>
      <p>Die Daten werden gelöscht, wenn wir Ihre Anfrage abschließend beantwortet haben und keine sonstigen
        Aufbewahrungspflichten dem
        entgegenstehen.</p> <br>
      <h2 class="text-md font-bold">Telefonische Kontaktaufnahme oder E-Mail-Kontakt</h2> <br>
      <p>
        Wir haben entsprechend der gesetzlichen Vorgaben eine Telefonnummer und E-Mail-Adresse auf unserer Webseite
        angegeben. Die
        auf diesen Wegen übermittelten Daten werden von uns automatisch gespeichert, um entsprechende Anfragen
        bearbeiten oder die anfragende Person kontaktieren zu können. Diese Daten werden von uns ohne Einwilligung nicht an Dritte weitergegeben.
      </p> <br>
      <p>
        Erfolgt die Kontaktaufnahme per Telefon oder über unsere E-Mail-Adresse zu vorvertraglichen bzw. vertraglichen Zwecken, so stützt
        sich die Verarbeitung personenbezogener Daten auf die Rechtsgrundlage des Art. 6 Abs. 1 lit. b DSGVO. Bei allen sonstigen
        Kontaktaufnahmen Ihrerseits stützt sich die Verarbeitung der personenbezogenen Daten durch uns auf unser berechtigtes Interesse
        gem. Art. 6 Abs. 1 lit. f DSGVO.
      </p> <br>
      <h2 class="text-md font-bold">Newsletterdaten</h2> <br>
      <p>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse und mit dem Empfang
        des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Für die Abwicklung der
        Newsletter nutzen wir Newsletterdiensteanbieter, die nachfolgend beschrieben werden.
      </p> <br>
      <p>
        Unsere versandten Newsletter ermöglichen uns die Analyse des Verhaltens der Newsletterempfänger. Hierbei kann u. a. analysiert
        werden, wie viele Empfänger die Newsletternachricht geöffnet haben und wie oft welcher Link im Newsletter angeklickt wurde. Mit
        Hilfe des sogenannten Conversion-Trackings kann außerdem analysiert werden, ob nach Anklicken des Links im Newsletter eine
        vorab definierte Aktion (z. B. Kauf eines Produkts auf dieser Website) erfolgt ist.
      </p> <br>
      <p>Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
        widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom
        Widerruf unberührt.</p> <br>
      <p>Wenn Sie keine Analyse wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in jeder Newsletternachricht einen
        entsprechenden Link zur Verfügung.</p> <br>
      <p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem
        Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters aus der
        Newsletterverteilerliste gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unberührt.
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
        einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger Mailings erforderlich ist. Die Daten aus der Blacklist werden nur für
        diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem
        Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO).
        Die Speicherung in der Blacklist ist zeitlich nicht befristet. Sie können der Speicherung widersprechen, sofern Ihre Interessen unser
        berechtigtes Interesse überwiegen</p> <br>
      <h2 class="text-md font-bold">Registrierung auf unserer Internetseite</h2> <br>
      <p>Die betroffene Person hat die Möglichkeit, sich auf der Internetseite des für die Verarbeitung Verantwortlichen unter Angabe von
        personenbezogenen Daten zu registrieren. Welche personenbezogenen Daten dabei an den für die Verarbeitung Verantwortlichen
        übermittelt werden, ergibt sich aus der jeweiligen Eingabemaske, die für die Registrierung verwendet wird. Die von der betroffenen
        Person eingegebenen personenbezogenen Daten werden ausschließlich für die interne Verwendung bei dem für die Verarbeitung
        Verantwortlichen und für eigene Zwecke erhoben und gespeichert. Der für die Verarbeitung Verantwortliche kann die Weitergabe an
        einen oder mehrere Auftragsverarbeiter, beispielsweise einen Paketdienstleister, veranlassen, der die personenbezogenen Daten
        ebenfalls ausschließlich für eine interne Verwendung, die dem für die Verarbeitung Verantwortlichen zuzurechnen ist, nutzt.</p>
      <br>
      <p>Durch eine Registrierung auf der Internetseite des für die Verarbeitung Verantwortlichen wird ferner die vom Internet-Service-Provider
        (ISP) der betroffenen Person vergebene IP-Adresse, das Datum sowie die Uhrzeit der Registrierung gespeichert. Die Speicherung
        dieser Daten erfolgt vor dem Hintergrund, dass nur so der Missbrauch unserer Dienste verhindert werden kann, und diese Daten im
        Bedarfsfall ermöglichen, begangene Straftaten aufzuklären. Insofern ist die Speicherung dieser Daten zur Absicherung des für die
        Verarbeitung Verantwortlichen erforderlich. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, sofern keine gesetzliche
        Pflicht zur Weitergabe besteht oder die Weitergabe der Strafverfolgung dient.</p> <br>
      <p>Die Registrierung der betroffenen Person unter freiwilliger Angabe personenbezogener Daten dient dem für die Verarbeitung
        Verantwortlichen dazu, der betroffenen Person Inhalte oder Leistungen anzubieten, die aufgrund der Natur der Sache nur registrierten
        Benutzern angeboten werden können. Registrierten Personen steht die Möglichkeit frei, die bei der Registrierung angegebenen
        personenbezogenen Daten jederzeit abzuändern oder vollständig aus dem Datenbestand des für die Verarbeitung Verantwortlichen
        löschen zu lassen.</p> <br>
      <p>Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen Person jederzeit auf Anfrage Auskunft darüber, welche
        personenbezogenen Daten über die betroffene Person gespeichert sind. Ferner berichtigt oder löscht der für die Verarbeitung
        Verantwortliche personenbezogene Daten auf Wunsch oder Hinweis der betroffenen Person, soweit dem keine gesetzlichen
        Aufbewahrungspflichten entgegenstehen. Die Gesamtheit der Mitarbeiter des für die Verarbeitung Verantwortlichen stehen der
        betroffenen Person in diesem Zusammenhang als Ansprechpartner zur Verfügung.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  components: {},
}
</script>

<style scoped>
</style>
